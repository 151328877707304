<template>
  <div class="register-repeat">
    <p>温馨提示</p>
    <span>当前手机号{{ phone }}已经注册过鸿联云账号</span>
    <van-button class="btn-border-red" block  @click="$router.push(`/login`)">登录已有账号</van-button>
  </div>
</template>

<script>
export default {
  computed: {
    phone () {
      return this.$route.query.phone
    },
    type () {
      return this.$route.query.type
    }
  }
}
</script>

<style lang="scss" scoped>
.register-repeat{
    padding: 98px 36px 0;
    >p{
        font-size: 24px;
        line-height: 32px;
        color: #002626;
        text-align: center;
        margin-bottom: 12px;
    }
    >span{
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
        text-align: center;
        margin-bottom: 48px;
    }
}
</style>
